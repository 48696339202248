export const AUTH_GROUPS = {
  HOSPITAL_OFFICER: 'HospitalOfficer',
  RESTRICTED_HOSPITAL_OFFICER: 'RestrictedHospitalOfficer',
  STAFFBANK_MANAGER: 'StaffBank Manager',
  RESTRICTED_STAFFBANK_MANAGER: 'RestrictedStaffBankManager',
  PAYROLL_MANAGER: 'Payroll Manager',
  COMMUNITY_MANAGER: 'Community Manager',
  LINK_SHIFT_INTEGRATION_MANAGER: 'Link | Shift Integration Manager',
  EXTERNAL_STAFFING_PROVIDER: 'External Staffing | Provider',
  TABLEAU_OFFICER: 'TableauOfficer',
  COMMUNITY_ADMIN: 'Community Admin',
  PROFILES_TAGS_MANAGER: 'Profiles | Tags Manager',
  PROFILES_LABELS_MANAGER: 'Profiles | Labels Manager',
  BULK_UNPUBLISH_SHIFTS: 'Bulk Unpublish Officer',
  UNLOCK_SHIFT_RATES: 'Unlock Shift Rates Officer',
  CREATE_NON_RESIDENT_SHIFT_OFFICER: 'Create Non Resident Shift Officer',
  CONTROL_READ_ONLY: 'ControlReadOnlyUser',
  CONTROL_INTERMEDIATE: 'ControlIntermediateUser',
  CONTROL_ADMIN_USER: 'ControlAdminUser',
  CASCADE_AUTHORIZER: 'External Staffing | Cascade Authorizer',
  CASCADE_UNLOCKER: 'External Staffing | Cascade Unlocker',
};
